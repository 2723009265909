.my-account {
  .page-title {
    font-size: 2.1875rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .account-nav {
    list-style: none;
    padding: 0;
    padding-top: 2.5rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 500;

    .menu-link {
      &.menu-link_active {
        pointer-events: none;
        color: $color-red-800;
      }
    }
  }

  .page-content {
    padding-top: 2.5rem;
  }

  &__dashboard {
    p {
      width: 43.125rem;
      max-width: 100%;
    }

    .unerline-link {
      text-decoration: underline;
    }
  }

  &__orders-list {
    overflow-x: auto;
    width: 100%;
  }

  .orders-table {
    border: 1px solid $border-color;
    width: 100%;

    thead {
      th {
        background-color: $border-color;
        padding: 1rem 1.875rem;
        text-transform: uppercase;
        font-size: 0.875rem;
      }
    }

    tbody {
      td {
        border-top: 1px solid $border-color;
        padding: 1.875rem;

        &:first-child {
          text-decoration: underline;
        }

        &:last-child {
          width: 12.5rem;
        }

        .btn {
          height: 3.125rem;
          font-size: 0.875rem;
        }
      }
    }
  }

  &__address {
    .notice {
      margin-bottom: 3.75rem;
    }

    &-list {
      display: flex;
      gap: 5.625rem;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }

    &-item {
      flex-grow: 1;

      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
        line-height: 1.5rem;
        text-transform: uppercase;
        margin-bottom: 0.875rem;
        font-weight: 500;

        h5 {
          font-size: 1.125rem;
          margin: 0;
        }

        a {
          font-size: 0.8125rem;
          border-bottom: 2px solid;
        }
      }

      &__detail {
        line-height: 1.5rem;

        p {
          margin: 0;
        }
      }
    }
  }

  &__edit {
    .btn-primary {
      width: 18.75rem;
      height: 3.75rem;
      max-width: 100%;
      text-transform: uppercase;
      font-size: 0.875rem;
    }
  }

  &__wishlist {
    .btn-remove-from-wishlist {
      position: absolute;
      left: 1.25rem;
      top: 1.25rem;
      z-index: 2;
      border: 0;
      background-color: #fff;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      transition: all .3s ease;
      box-shadow: 0 0 1px rgba(0,0,0,0.1);
      opacity: 0;
      visibility: hidden;
    }

    .product-card:hover .btn-remove-from-wishlist {
      opacity: 1;
      visibility: visible;
    }
  }
}