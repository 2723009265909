.about-us {
  .page-title {
    font-size: 2.1875rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
  }

  &__content {
    line-height: 1.875rem;
  }
}