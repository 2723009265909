.service-promotion {
  &__icon .flaticon {
    font-size: 3.25rem;
  }
  
  &__content {
    font-size: 0.9375rem;
  }

  &.horizontal {
    padding-top: 2.9375rem;
    padding-bottom: 2.3rem;

    .service-promotion__icon .flaticon {
      font-size: 2.8125rem;
    }
  }
}