.lookbook {
  .page-title {
    @include font-size(2.1875rem);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
  }

  &-collection__item {
    display: block;
    padding-top: 57.68%;

    &.size-lg {
      padding-top: 119.7%;
    }

    &-image {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.popover-point {
  padding: 1rem;
  border: 0;
  background-color: transparent;

  > span {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.3rem solid #fff;
    background-color: $third;
    border-radius: 1rem;
  }

  &.type2 {
    padding: 0.2rem;

    @include media-breakpoint-up(md) {
      padding: 1rem;
    }

    > span {
      background-color: $color-white;
      border-radius: 3rem;
      border: 0;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.625rem;
      height: 0.625rem;
      box-shadow: 0 0 0 0.2625rem rgba(255,255,255,.3);
      font-size: 0.5625rem;
      padding-bottom: 0.05rem;

      @include media-breakpoint-up(md) {
        width: 2.625rem;
        height: 2.625rem;
        box-shadow: 0 0 0 0.5625rem rgba(255,255,255,.3);
        font-size: 1.5625rem;
        padding-bottom: 0.2rem;
      }
    }
  }

  &.type3 {
    padding: 0.2rem;

    @include media-breakpoint-up(md) {
      padding: 1rem;
    }

    > span {
      background-color: $color-white;
      border-radius: 3rem;
      border: 0;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.625rem;
      height: 0.625rem;
      box-shadow: 0 0 0 0.2625rem rgba(255,255,255,.3);
      font-size: 0.5625rem;
      padding-bottom: 0.05rem;

      @include media-breakpoint-up(md) {
        width: 1.75rem;
        height: 1.75rem;
        box-shadow: 0 0 0 0.375rem rgba(255,255,255,.3);
        font-size: 1.5625rem;
        padding-bottom: 0.2rem;
      }
    }
  }
}

.lookbook-products {
  > h2 {
    pointer-events: none;
  }
}