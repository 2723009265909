.footer-mobile {
  max-width: 100%;
  padding-top: 0.625rem;
  padding-bottom: 0.125rem;
  z-index: $zindex-dropdown;
  opacity: 0;
  bottom: -6.25rem;
  transition: all .32s;

  .flaticon {
    font-size: 1.125rem;
  }

  .cart-amount,
  .wishlist-amount {
    top: -5px;
    left: calc(100% - 5px);
    width: $header-cart-amount-width;
    height: $header-cart-amount-width;
    border-radius: 100%;
    background: $header-cart-amount-bg;
    color: $header-cart-amount-color;
    font-size: $header-cart-amount-font-size;
    line-height: $header-cart-amount-width;
    text-align: center;
  }

  &.position-fixed {
    opacity: 1;
  }

  &.footer-mobile_initialized {
    bottom: 0;
    transition: all .32s;
  }
}

.footer-mobile__link {
  font-size: 0.8125rem;
}
