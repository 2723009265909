body.not-found-page {
  background: url(/assets/images/pattern_bg.png) center no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .header {
    background: transparent;
  }
}

.page-not-found {
  // position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;

  .content {
    width: 31.25rem;
    max-width: 100%;
    text-align: center;

    h2 {
      @include font-size(6.25rem);
      font-weight: $font-weight-bold;
    }

    .btn {
      width: 21.25rem;
      max-width: 100%;
      height: 3.75rem;
      font-size: 0.875rem;
    }
  }
}
