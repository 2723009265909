.testimonial-card {
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 2.5rem;
  background: url(/assets/images/home/demo18/testimonial-mark.png)
    calc(100% - 1.75rem) 1.5625rem no-repeat;
  transition: all 0.3s ease;

  h3 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: $body-color-secondary;
    margin-bottom: 2rem;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 1.25rem;

    .user-avatar {
      border-radius: 10rem;
      width: 4.375rem;
      height: 4.375rem;
      overflow: hidden;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user-detail {
      h4 {
        font-size: 0.9375rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
      }

      p {
        font-size: 0.875rem;
        color: $body-color-secondary;
        margin-bottom: 0;
      }
    }
  }

  &:not(.swiper-slide-active) {
    opacity: 0.5;
  }
}
