$sidebar-width: 18.75rem;
$sidebar-space: 3.75rem;

.shop-sidebar {
  width: $sidebar-width;
  min-width: $sidebar-width;
}

.aside-filters,
.shop-sidebar {
  .accordion-button__icon {
    width: 0.625rem;
    height: 0.625rem;
  }
}

.shop-list {
  max-width: 100%;

  @include media-breakpoint-up(lg){
    max-width: calc(100% - #{$sidebar-width + $sidebar-space});
  }
}

.side-sticky {
  position: fixed;
  top: 0;
  right: -$aside-width;
  left: auto;
  width: $aside-width;
  max-width: 100%;
  height: 100vh;
  padding: 0 $aside-padding-x / 2 1.75rem;
  transition: left .35s, right .35s;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: $zindex-modal;

  &.aside_visible {
    right: 0;
  }

  .accordion {
    padding-left: $aside-padding-x / 2;
    padding-right: $aside-padding-x / 2;
  }

  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 0;
    right: auto;
    left: 0;
    width: $sidebar-width;
    height: 100%;
    padding: .375rem 0 0;
    margin-right: $sidebar-space;
    z-index: 1;

    .accordion {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
