.image-banner {
  position: relative;
  min-height: 18.75rem;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(sm) {
    min-height: 30rem;
  }

  @include media-breakpoint-up(lg) {
    min-height: 43.75rem;
  }

  &__content {
    position: relative;
    z-index: 1;
    text-align: center;

    h2 {
      @include font-size(3.75rem);
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}