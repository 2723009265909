.search-field__actor {
  svg {
    @include transition($transition-fade);
  }

  .btn-close-lg {
    position: absolute;
    top: 3px;
    left: 3px;
    padding: 0;
    @include transition($transition-fade);
    opacity: 0;
    visibility: hidden;
  }

  .js-content_visible & {
    svg {
      opacity: 0;
      visibility: hidden;
    }

    .btn-close-lg {
      opacity: 1;
      visibility: visible;
    }
  }
}

.search-popup {
  top: 100%;
  left: 0;
  padding-top: $search-popup-padding-top * 0.7;
  padding-bottom: $search-popup-padding-bottom * 0.7;;
  border-top: $mega-menu-border;
  background-color: $header-background;
  box-shadow: $box-shadow;

  @include media-breakpoint-up(xl) {
    padding-top: $search-popup-padding-top;
    padding-bottom: $search-popup-padding-bottom;
  }

  .search-suggestion {
    transition: $menu-appear-animation;
  }
}

.search-popup__reset,
.search-popup__submit {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  padding: 0;
  padding-bottom: 0.625rem;
  @include transition($transition-fade);
  color: $body-color-secondary;
  outline: none;
}

.search-popup__reset {
  opacity: 0;
  visibility: hidden;
}

.search-popup__input {
  padding-bottom: 0.625rem;
  border: 0;
  border-bottom: 2px solid $body-color-secondary;
  outline: none;
}

.search-field__focused {
  .search-popup__input {
    border-bottom-color: $body-color;
  }

  .search-popup__reset,
  .search-popup__submit {
    color: $body-color;
  }

  .search-popup__submit {
    opacity: 0;
    visibility: hidden;
  }

  .search-popup__reset {
    opacity: 1;
    visibility: visible;
  }

  .search-result {
    position: relative;
    width: auto;
    margin-top: 0;
    transition: $menu-appear-animation;
    opacity: 1;
    visibility: visible;
  }

  .search-suggestion {
    position: absolute;
    margin-top: -.5rem;
    opacity: 0;
    visibility: hidden;
  }
}

.search-popup__results {
  margin-top: 1.75rem;
}

.search-result {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: $search-result-min-height * 0.6;
  margin-top: .5rem;
  padding-bottom: $search-result-bottom-space * 0.6;
  opacity: 0;
  visibility: hidden;

  .product-card {
    --bs-gutter-x: 1.75rem;
  }

  .pc__img-wrapper {
    padding-top: $search-result-img-height * 0.54;
  }

  @include media-breakpoint-up(xl) {
    min-height: $search-result-min-height * 0.8;
    padding-bottom: $search-result-bottom-space * 0.8;

    .pc__img-wrapper {
      padding-top: $search-result-img-height * 0.8;
    }
  }

  @include media-breakpoint-up(xxl) {
    min-height: $search-result-min-height;
    padding-bottom: $search-result-bottom-space;

    .pc__img-wrapper {
      padding-top: $search-result-img-height;
    }
  }
}