
.example {
  color: #222 !important;
  background-color: #fff !important;
  z-index: 12234;
  //   display: none;
}
.theme-19 {
  .swiper-scrollbar-drag {
    background-color: #c32929;
  }
}
.border-top-1-dashed {
  border-top: 1px dashed !important;
}

.theme-22 {
  .color-body {
    color: #222222 !important;
    .navigation__link {
      color: #222222 !important;
    }
  }
}
.product-single__thumbnail .swiper-slide {
  opacity: 0.5;
  cursor: pointer;
}
.product-single__thumbnail .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
  cursor: pointer;
}
.item-zoom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.8125rem;
  height: 2.8125rem;
  position: absolute;
  right: 1.875rem;
  bottom: 1.875rem;
  border-radius: 2rem;
  background-color: #fff;
  transition: all 0.3s ease;
}
.swiper-thumb-3 {
  .swiper-slide {
    @media (min-width: 500px) {
      width: fit-content !important;
    }
  }
}
.swiper-thumb-4 {
  .swiper-slide {
    height: fit-content !important;
  }
}
.nav-link {
  cursor: pointer;
}

a {
  cursor: pointer;
}
.rc-slider {
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  .rc-slider-handle {
    border: solid 2px #000;
    opacity: 1 !important;

    background-color: #fff !important;

    width: 16px;
    height: 16px;
    margin-top: -7px;
    // transition: 0.3s;
    &:hover {
      border-color: #000;
    }
  }
  .rc-slider-handle-dragging {
    border-color: #000 !important;
    box-shadow: none !important;
    // transform: scale(1.3);
  }
  .rc-slider-track,
  .rc-slider-tracks {
    background-color: #000;
  }
}
.page-wrapper {
  padding-top: 90px;
  @media (max-width: 1000px) {
    padding-top: 60px;
  }
}
.page-wrapper-3 {
  @media (max-width: 991px) {
    padding-top: 60px;
  }
}
.page-wrapper-2 {
  padding-top: 40px;
  @media (max-width: 1000px) {
    padding-top: 80px;
  }
}
.hero-8 .swiper-pagination-bullet:first-child {
  margin-left: 4px !important;
}
.blog-title {
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
}
.menu-active {
  color: #bb8c1c !important;
}
body {
  .nav-20.theme-color .navigation__item {
    .menu-active {
      color: #bb8c1c !important;
    }
  }
}
body {
  .theme-22 .color-body .navigation__item {
    .menu-active {
      color: #bb8c1c !important;
    }
  }
}

@media (max-width: 991px) {
  .product-single__type-4 {
    max-width: 100vw;
    overflow: hidden !important;
  }
}
.product-single__addtocart.product-single__grouped .grouped-item {
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .grouped-item__price {
      margin-left: 0;
    }
  }
}
.btn-outline-primary:hover {
  a {
    color: #fff !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.product-single__thumbnail .swiper-slide {
  height: fit-content !important;
}

#page5{
  width: 100%;
  height: 100vh;
  background-color: beige;
  display: flex;
  flex-wrap: nowrap;
}
#page5 .red{
  width: 100%;
  background-color: rgb(241, 241, 194);
}
.container0{
  width: 100%;
  display: flex;
}
#page5 .left-div{
  margin-left: 90px;
  border: 2px solid black;
  width: 40%;
  height: 100vh;
}
.left-div video{
 width: 100%;
 height: 100%;
}
.right-div h1{
  color: #006990;
  font-size: 70px;
  padding-left: 70%;
  text-align: center;
  padding-top: 20px;
}
.red video{
  width: 90%;
  height: 90%;
  padding-top: 50px;
}

#main2{
  overflow:hidden;

}

.testsect{
	height: 100vh;
  width:100%;
}

.cont{
  position: relative;
  display:flex;
  flex-direction:row;
  width: 100vw;
  height: 100vh;
  overflow:hidden;
}

.panel2{

 
  width: 100%;
  display: flex;
}

.inner2{
 width:100vw;
 display: flex;
 justify-content: center;
}

h2, h3 {
  font-size: 3.6rem;
}
.out-of-stock{
  font-family: 'Lato','SofiaProRegular';
}
.badge{
  background-color:rgb(220, 53, 69);
  padding: 0.325rem;
  border-radius: 0%;
}
.note{
  background-color:rgb(220,53,69);
  padding: 0.325rem;
  border-radius: 0%;
  color: #fff;
}
.p {
  width: 100%;
  text-align: center;
  // padding: 0;
  // font-size: 1rem;
  text-wrap: balance;
  opacity: 0;
}
.h2{
  opacity: 0;
}

.menu-button {
  position: fixed;
  left: 47%;
  z-index: 99;
  // clip-path : polygon(0% 0%, 100% 0%, 50% 100%);
  clip-path: polygon(0 0, 100% 1%, 100% 60%, 50% 100%, 0 60%);
  // background-color:rgba(0, 247, 255, 0.699);
  background-color: rgb(233 194 101);

}
.mainnn-wrapper h1{
  font-size: 65px;
}
.mainnn-wrapper h2{
  font-size: 20px;
}

.panel2 .zoom_img{
  height: 100vh;
  object-fit: cover;
}

.mobile-vid{
  width: 50%;
}
.mobile-swiper{
  display: none;
}

/* Media query for devices with a max width of 600px */
@media (max-width: 900px) {
  // .text-container {
  //   font-size: 20px; /* Adjust font size for mobile */
  //   line-height: 1.4; /* Adjust line height to fit more text in one line */
  //   word-spacing: 1px;
  //   text-align: center;
   
  //   // text-wrap: nowrap;
  // }
  h2,h3{
    font-size: 28px;
    
  }
  .text_reveal{
    margin: 25%;
    padding-top: 50%;
  }
  .last-slide{
    margin-left: 275px;
  }
  .first-slide{
    display: none;
  }
  .cont{
    display: none;
  }
}
.s-font{
  font-style: italic;
  font-weight: 100;
}


/* Style for the navigation arrows */
.swiper-button-next,
.swiper-button-prev {
  color: white;
  font-size: 14px; /* Smaller size */
  width: 34px; /* Adjust the width */
  height: 34px; /* Adjust the height */
  background: rgba(0, 0, 0, 0.5); /* Add a subtle background for visibility */
  border-radius: 50%; /* Circular shape */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
  background: rgba(255, 255, 255, 0.8); /* Highlight background */
  color: black; /* Contrast text on hover */
}

/* Position adjustments to avoid overlapping */
.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
}

/* Optional: To prevent arrows from blocking content */
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 14px; /* Adjust the arrow icon size */
}


@media (max-width: 768px) {
  .mainnn-title {
    font-size: 1.2rem;
  }
  .cat-title{
    font-size: 18px;
  }
  .swiper-slide {
    // display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mainnn-subtitle {
    font-size: 1rem;
  }
  .mainnn-wrapper h1{
    font-size: 40px;
    text-wrap: nowrap;
  }

  .mainnn-content__title, .mainnn-content__subtitle {
    font-size: 0.8rem;
  }
 
  .right-side__img .bottle-bg{
  height: 275px;
 }
  .bottle-img{
    width: 185px;
    // height: 175px;
  }
  
  .mobile-vid{
    width: 100%;
  }
  #end{
    height: max-content;
    padding-top: 30px;
  }
   
 
}

.magnifier-container {
  position: relative;
  display: inline-block;
}

.magnifier-glass {
  position: absolute;
  border-radius: 50%;
  border: 3px solid #bb8c1c;
  cursor: none;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.8);
  /* Adjust the size of the magnifier */
  width: 200px;
  height: 200px;
  /* Hide the magnifier by default */
  display: none;
  transition: all 0.3 ease-in-out;
}

.magnifier-glass img {
  width: 100%;
}

.magnifier-container img {
  display: block;
}
* {
  box-sizing: border-box;
  // user-select: none;
}





.hero{
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.content2 {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
   width: 100%;
}

footer {
  background-color: black;
  color: white;
}

.content img{
  object-fit: cover;
  height: 100vh;
}
.nav-item{
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  line-height: 36px;
  text-decoration: none;

}
.swiper-center .swiper-wrapper{
  justify-content: center;
}
// Mobile Slider css

// section{
// 	height: 100vh;
//   width:100vw;
// }

.mobilecontainer{
  position: relative;
  display:flex;
  flex-direction:row;
  width: 100vw;
  height: 100vh;
  overflow:hidden;
}

.mobilepanel{
  width: 100vw;
  height:100%;
  display: flex;
}

.inner{
 width:100vw;
//  display: flex;
//  justify-content: center;
}
.page2{
  height: 100vh;
}
.nav-link {
  position: relative;
}

.nav-link.active::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transition: none;
}

.map-container{
  overflow-y: hidden;
  padding: 0;
  margin: 0;
}

.sec-two-h2 {
  font-style: italic;
  color: #C58B14;
}

.sub-title {
  font-family: Lato, sans-serif, Serif;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    opacity: 0;;
}

.para-sec {
  font-family: Lato, sans-serif, Serif;
    font-size: 1rem;
    text-align: center;
    text-wrap: balance;
}
/* Progress Bar Container */
.free-shipping-progress {
  margin-top: 20px;
  text-align: center;
}

.free-shipping-progress p {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

/* Progress Bar */
.progress {
  background-color: #cacaca;
  border-radius: 25px;
  height: 12px;
  width: 100%;
  overflow: hidden;
}

.progress-bar {
  background-color: #f8cb62; 
  width: 100%; 
  background-image: linear-gradient(
        45deg, #e4a921 25%, 
        transparent 25%, transparent 50%, 
        #e4a921 50%, #e4a921 75%,
        transparent 75%, transparent); 
  animation: progressAnimationStrike 6s;
}

.success {
  font-size: 14px;
  color: #af4c4c; /* Same green color as the progress bar */
}

.nav-circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.nav-circle {
  width: 15px;
  height: 15px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-circle.active {
  background-color: #bb8c1c;
}
/* styles/globals.css */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000062; /* Change this to match your site's background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.section3 .panel2{
  padding-top: 50px;
}
.preloader-gif {
  width: 75px; /* Adjust the size as needed */
  height: auto;
}
.loader {
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-top: 5px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.section4 .text_reveal{
  margin: 19%;
}

@media (max-width: 575.98px) {
  .product-single__details-list__content {
      padding-left: 0;
  }
}