@import 'settings';
@import 'slideshow-split';
@import 'social_follow';

.slideshow-boxed-right {

  > .slideshow {
    height: 26.25rem;
    background-color: #eee;
    border-radius: 4px;

    @include media-breakpoint-up(lg) {
      height: 31.875rem;
      width: 44rem;
      margin-left: auto;
      margin-right: 0;
      margin-top: 1.875rem;
    }

    @include media-breakpoint-up(xl) {
      width: 54rem;
    }

    @include media-breakpoint-up(xxl) {
      width: 65.625rem;
    }
  }
}
