.size-guide {
  &.modal-dialog {
    width: 60rem;
    max-width: calc(100% - 1rem);
  }

  .modal-header {
    background-color: #FAF9F8;
    border-bottom: 0;
    padding: 1.75rem 2.5rem;
    text-transform: uppercase;

    .modal-title {
      font-size: 1rem;
    }
  }

  .modal-body {
    padding: 2.5rem;
  }
  
  &__wrapper {
    display: flex;
    gap: 2.6875rem;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__image {
    flex: 0 0 42%;
    max-width: 42%;

    @include media-breakpoint-down(md) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &__detail {
    flex: 1;

    > h5 {
      margin-bottom: 1.875rem;
    }

    table {
      font-size: 0.875rem;
      margin-bottom: 1.875rem;
      width: 100%;

      thead {
        th {
          color: $secondary;
          padding-bottom: 0.8125rem;
        }
      }

      tbody {
        td {
          line-height: 2.1875rem;
        }
      }
    }
  }
}

.delivery-modal {
  &.modal-dialog {
    width: 37.5rem;
    max-width: calc(100% - 1rem);
  }

  .modal-header {
    background-color: #FAF9F8;
    border-bottom: 0;
    padding: 1.75rem 2.5rem;
    text-transform: uppercase;

    .modal-title {
      font-size: 1rem;
    }
  }

  .modal-body {
    padding: 2.5rem;

    p {
      line-height: 1.875rem;
      margin-right: 3.875rem;
      margin-bottom: 1.875rem;
    }
  }
}

.cookieConsentContainer {
  position: fixed;
  right: 1.25rem;
  bottom: 1.25rem;
  width: 18.75rem;
  max-width: calc(100% - 2.5rem);
  background-color: #222;
  color: #fff;
  padding: 1.875rem;
  z-index: 1050;

  .cookieButton a {
    background-color: #5c5c5c;
    color: #fff;
    height: 2.5rem;
    text-transform: uppercase;
    font-size: 0.875rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .cookieDesc p {
    font-size: 0.8125rem;
  }
}