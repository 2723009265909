.shopping-cart {
  display: flex;
  flex-direction: column;
  gap: 3.625rem;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }

  .cart-table__wrapper {
    padding-top: 3.125rem;
    flex-grow: 1;
  }

  &__totals-wrapper {
    .sticky-content {
      padding-top: 3.125rem;
    }
    
    .mobile_fixed-btn_wrapper {
      .button-wrapper:not(.fixed-btn) {
        padding: 0;
      }

      .fixed-btn {
        position: fixed;
        left: var(--bs-gutter-x, 0.9375rem);
        right: var(--bs-gutter-x, 0.9375rem);
        bottom: 4rem;
        width: auto;

        @include media-breakpoint-up(md) {
          bottom: 1rem;
        }

        @include media-breakpoint-up(lg) {
          position: static;
        }
      }
    }

    .btn-checkout {
      width: 100%;
      height: 3.75rem;
      font-size: 0.875rem;
    }
  }

  &__totals {
    border: 1px solid #222;
    margin-bottom: 1.25rem;
    padding: 2.5rem 2.5rem 0.5rem;
    max-width: 100%;

    @include media-breakpoint-up(xl) {
      width: 26.25rem;
    }

    > h3 {
      font-size: 1rem;
      text-transform: uppercase;
      margin-bottom: 1.25rem;
    }

    table {
      width: 100%;

      th, td {
        border-bottom: 1px solid $border-color;
        padding: 0.875rem 0;
        font-size: 0.875rem;
        font-weight: 500;
      }

      th {
        text-transform: uppercase;
        vertical-align: baseline;
      }

      tr:last-child {
        th, td {
          border-bottom: 0;
        }
      }
    }
  }

  .cart-table {
    width: 100%;

    th, td {
      @include media-breakpoint-up(md) {
        border-bottom: 1px solid $border-color;
      }
    }

    thead {
      display: none;

      @include media-breakpoint-up(md) {
        display: table-header-group;
      }

      th {
        font-size: 0.875rem;
        text-transform: uppercase;
        font-weight: 500;
        padding: 0 0 0.625rem;
      }
    }

    tbody {
      tr {
        position: relative;
        display: block;
        padding: 1.25rem 0;
        border-bottom: 1px solid $border-color;
        transition: all .3s ease;

        @include media-breakpoint-up(md) {
          display: table-row;
          padding: 0;
          border: 0;
        }

        &:first-child {
          border-top: 1px solid $border-color;

          @include media-breakpoint-up(md) {
            border: 0;
          }
        }

        &:after {
          content: '';
          display: block;
          clear: both;

          @include media-breakpoint-up(md) {
            display: none;
          }
        }

        td {
          transition: all 0.32s cubic-bezier(0.39, 0.575, 0.565, 1);

          > * {
            transition: all 0.32s cubic-bezier(0.39, 0.575, 0.565, 1);
            overflow: hidden;
            max-height: 12rem;
          }
        }

        &._removed {
          td {
            padding-top: 0;
            padding-bottom: 0;

            > * {
              max-height: 0;
            }
          }
        }
      }

      td {
        display: block;
        margin-left: 9.375rem;

        @include media-breakpoint-up(md) {
          padding: 1.875rem 0;
          display: table-cell;
          margin: 0;
        }

        &:first-child {
          width: 9.375rem;
          float: left;
          margin-left: 0;

          @include media-breakpoint-up(md) {
            float: none;
          }
        }

        .shopping-cart__product-price {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
          }
        }

        .shopping-cart__subtotal {
          float: right;
          display: block;
          margin-top: -2.2rem;

          @include media-breakpoint-up(md) {
            float: none;
            margin: 0;
          }
        }

        .remove-cart {
          position: absolute;
          right: 0;
          top: 1rem;

          @include media-breakpoint-up(md) {
            position: static;
          }
        }
      }
    }

    .qty-control {
      margin: 0.5rem 0;

      @include media-breakpoint-up(md) {
        width: 7.25rem;
        margin: 0;
      }

      &__number {
        border: 0;

        @include media-breakpoint-up(md) {
          border: 2px solid $border-color;
          height: 3.125rem;
          padding: 0 2rem;
          min-width: 6.875rem;
        }
      }

      &__reduce, &__increase {
        font-size: 1rem;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
      }

      &__reduce {
        left: 0;

        @include media-breakpoint-up(md) {
          left: 1.25rem;
        }
      }

      &__increase {
        right: 0;

        @include media-breakpoint-up(md) {
          right: 1.25rem;
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      padding-top: 1.875rem;
      gap: 1.875rem;
      flex-wrap: wrap;

      form {
        max-width: 100%;
      }

      .form-control {
        width: 23.125rem;
        max-width: 100%;
        height: 3.875rem;
      }

      button {
        height: 3.875rem;
      }
    }
  }

  &__product {
    &-item {
      display: flex;
      align-items: center;
      gap: 1.875rem;

      img {
        width: 7.5rem;
        height: auto;
      }

      &__detail {
        h4 {
          font-size: 1rem;
          font-weight: 400;
          margin-bottom: 0;
        }
      }

      &__options {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 0.5rem;

        li {
          font-size: 0.875rem;
          color: $secondary;
        }
      }
    }

    &-price {
      font-size: 1rem;
      color: $secondary;
    }
  }

  &__subtotal {
    font-size: 1rem;
    font-weight: 500;
  }
}