.checkout-form {
  display: flex;
  gap: 3.625rem;

  @include media-breakpoint-down(xl) {
    flex-direction: column;
  }

  .billing-info__wrapper {
    padding-top: 3.125rem;
    flex-grow: 1;

    .form-floating > label, .form-label-fixed > .form-label {
      color: $secondary;
    }
  }

  .checkout__totals-wrapper {
    .sticky-content {
      padding-top: 3.125rem;
    }

    .btn-checkout {
      width: 100%;
      height: 3.75rem;
      font-size: 0.875rem;
    }
  }

  .checkout__payment-methods {
    border: 1px solid $border-color;
    margin-bottom: 1.25rem;
    padding: 2.5rem 2.5rem 1.5rem;
    width: 26.25rem;
    
    @include media-breakpoint-down(xl) {
      width: 100%;
    }

    label {
      font-size: 1rem;
      line-height: 1.5rem;

      .option-detail {
        font-size: 0.875rem;
        margin: 0.625rem 0 0;
        display: none;
      }
    }

    .form-check-input:checked ~ label .option-detail {
      display: block;
    }

    .policy-text {
      font-size: 0.75rem;
      line-height: 1.5rem;

      > a {
        color: $color-red-800;
      }
    }
  }
}

.checkout__totals {
  border: 1px solid #222;
  margin-bottom: 1.25rem;
  padding: 2.5rem 2.5rem 0.5rem;
  width: 26.25rem;
  
  @include media-breakpoint-down(xl) {
    width: 100%;
  }

  > h3 {
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 1.25rem;
  }

  table {
    width: 100%;
  }

  .checkout-cart-items {
    thead {
      th {
        border-bottom: 1px solid #e4e4e4;
        padding: 0.875rem 0;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }

    tbody {
      td {
        padding: 0.40625rem 0;
        color: $secondary;
      }

      tr:first-child {
        td {
          padding-top: 0.8125rem;
        }
      }

      tr:last-child {
        td {
          padding-bottom: 0.8125rem;
          border-bottom: 1px solid $border-color;
        }
      }
    }
  }

  .checkout-totals {
    th, td {
      border-bottom: 1px solid $border-color;
      padding: 0.875rem 0;
      font-size: 0.875rem;
      font-weight: 500;
    }

    tr:last-child {
      th, td {
        border-bottom: 0;
      }
    }
  }
}