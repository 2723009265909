.block-newsletter {
  @include media-breakpoint-up(sm) {
    padding: 0 1rem;
  }

  .block__title {
    color: $newsletter-title-color;
    margin-bottom: 1.125rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  .block-newsletter__form {
    display: flex;
    gap: 1.25rem;
  }

  .form-control {
    border-width: 1px;
    border-color: $newsletter-form-input-border;
  }

  .btn {
    font-size: $newsletter-form-input-font-size;
    background-color: $newsletter-form-button-bg;
  }

  &.dark {
    .block__title {
      color: inherit;
    }

    .form-control {
      border-color: $newsletter-dark-form-input-border;
      background-color: $newsletter-dark-form-input-bg;
      color: $newsletter-dark-form-input-color;

      &::placeholder {
        color: $newsletter-dark-form-input-placeholder-color;
      }
    }

    .btn {
      border-color: $newsletter-dark-form-button-border;
      background-color: $newsletter-dark-form-button-bg;
      color: $newsletter-dark-form-button-color;
    }
  }
}

.newsletter-popup {
  width: 56.25rem;
  max-width: calc(100% - 1rem);

  .modal-content {
    overflow: hidden;

    .btn-close {
      position: absolute;
      right: 0.75rem;
      top: 1rem;
    }
  }

  .block-newsletter {
    padding: 1.875rem 1.25rem;

    @include media-breakpoint-up(md) {
      padding: 3.5rem 2.5rem;
    }
  }
}