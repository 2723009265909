//
// Base styles
//

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  @include border-radius(0);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &::after {
    flex-shrink: 0;
    margin-left: auto;
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: $accordion-icon-transform;
    @include transition($accordion-icon-transition);
  }

  &.collapsed {
    border-bottom-width: 0;
  }

  // Accordion icon

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    outline: 0;
    // border-color: $accordion-button-focus-border-color;  // Not used in Uomo design
    // box-shadow: $accordion-button-focus-box-shadow;      // Not used in Uomo design
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  // &:first-of-type {    // Uomo accordions don't have border-radius
  //   .accordion-button {
  //     @include border-top-radius($accordion-border-radius);
  //   }
  // }

  &:last-of-type {
    .accordion-button {
      // Only set a border-radius on the last item if the accordion is collapsed
      &.collapsed {
        border-bottom-width: $accordion-border-width;
        // @include border-bottom-radius($accordion-border-radius); // Uomo accordions don't have border-radius
      }
    }

    .accordion-collapse {
      border-bottom-width: $accordion-border-width;
      // @include border-bottom-radius($accordion-border-radius); // Uomo accordions don't have border-radius
    }
  }
}

.accordion-collapse {
  border: solid $accordion-border-color;
  border-width: 0 $accordion-border-width;
}


// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
  .accordion-button {
    border-right: 0;
    border-left: 0;
    @include border-radius(0);
  }

  .accordion-collapse {
    border-width: 0;
  }

  .accordion-item {
    &:first-of-type {
      .accordion-button {
        border-top-width: 0;
        @include border-top-radius(0);
      }
    }

    &:last-of-type {
      .accordion-button.collapsed {
        border-bottom-width: 0;
        @include border-bottom-radius(0);
      }
    }
  }
}
