.order-tracking {
  width: 31.25rem;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;

  .btn-track {
    height: 3.75rem;
    font-size: 0.875rem;
  }
}